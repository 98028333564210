import React from 'react'
import { Grid, List, Popup, Icon, Container, Responsive } from 'semantic-ui-react'
import { KpButton } from "@elements/index"
import { LaybyService } from '@services/index'

import {
    GridItemSection,
    PlateTypeDescriptionSection,
    LaybyCalculatorResultCard,
    ResultKeyInfoContainer,
    ResultKeyInfo,
    LaybyHighlight,
    ContentContainer,
    LaybyCalculatorListContent,
    PlateImage,
    GridItemHeading,
    MobileLaybyCalculatorButtonContainer,
    LaybyCalculatorTotalPriceContainer
} from './LaybyCalculator.styles'
import { H1, Legal, Paragraph2 } from '@styles/Global.styles'
import theme from '@styles/theme'
import { PlateFormat } from 'src/models/PlateFormat/PlateFormat'
import { LaybyCalculatorOption } from './Types'

interface LaybyProps {
    apiUrl: string
    plateFormats: PlateFormat[]
    calculatorOptions: LaybyCalculatorOption[]
}
interface LaybyStates {
    selectedPlateFormatId: number
    selectedPaymentFrequencyId: number
    isLoading: boolean
}

class LaybyCalculator extends React.Component<LaybyProps, LaybyStates>{

    constructor(props: LaybyProps) {
        super(props);
        this.state = {
            selectedPlateFormatId: 0,
            selectedPaymentFrequencyId: 0,
            isLoading: true
        }
    }
    handlePlateTypeChange = (plateTypeId: number) => {
        if (plateTypeId !== this.state.selectedPlateFormatId) {
            this.setState({
                selectedPlateFormatId: plateTypeId,
                selectedPaymentFrequencyId: 0
            });
        }
    }
    handlePaymentFrequencyChange = (frequencyId: number) => {
        if (frequencyId !== this.state.selectedPaymentFrequencyId) {
            this.setState({
                selectedPaymentFrequencyId: frequencyId
            });
        }
    }

    componentDidMount() {
        let promises = this.props.plateFormats.map((plateFormat) => {
            return LaybyService.getLaybyQuotesForAmount(this.props.apiUrl, plateFormat.basePrice).then((laybyQuotes: any[]) => {
                if (this.props.calculatorOptions.length < this.props.plateFormats.length) {
                    this.props.calculatorOptions.push({
                        LaybyQuotes: laybyQuotes.map((laybyQuote) => {
                            return {
                                installmentFrequency: laybyQuote.DaysInPeriod,
                                installmentCount: laybyQuote.NumPeriods,
                                depositAmount: laybyQuote.DepositAmount,
                                paymentAmount: laybyQuote.MinRegularAmount,
                                totalAmount: laybyQuote.LayByAmount,
                                bracketName: 'weekly'
                            }
                        }),
                        PlateFormat: plateFormat
                    });
                }
            });
        });

        Promise.all(promises).then(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div>
                <H1 textAlign='center'>Layby Calculator</H1>
                {
                    !isLoading &&
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column floated='left' mobile={16} tablet={8} computer={5}>
                                    <ContentContainer>
                                        <GridItemSection>
                                            <Container>
                                                <GridItemHeading>
                                                    1. Plate type
                                                    <Popup basic position='right center' trigger={<Icon className='icon-align-top' name='question circle' color='orange' size='small' />}>
                                                        <Popup.Content>
                                                            <Paragraph2 color='#707070'>
                                                                To work out your Layby installments & payment options, first choose the format of your plate types from fixed to flexi combination; then pick your payment frequency and our calculator will work out what your total is and what your instalments are.
                                                                <br></br>
                                                                <br></br>
                                                                A handy tool to find the right Layby plan for your needs.
                                                            </Paragraph2>
                                                        </Popup.Content>
                                                    </Popup>
                                                </GridItemHeading>
                                            </Container>
                                            <List horizontal>
                                                {
                                                    this.props.calculatorOptions.map((calculatorOption, index) => {
                                                        return (
                                                            <List.Item key={index}>
                                                                <LaybyCalculatorListContent disabled={index !== this.state.selectedPlateFormatId} onClick={() => this.handlePlateTypeChange(index)}>
                                                                    {calculatorOption.PlateFormat.name}
                                                                </LaybyCalculatorListContent>
                                                            </List.Item>
                                                        )
                                                    })
                                                }
                                            </List>
                                            <PlateTypeDescriptionSection>
                                                {this.props.plateFormats[this.state.selectedPlateFormatId].description}
                                            </PlateTypeDescriptionSection>
                                            <PlateTypeDescriptionSection>
                                                <PlateImage src={this.props.plateFormats[this.state.selectedPlateFormatId].plateImageUrl} alt="plate image" />
                                            </PlateTypeDescriptionSection>
                                        </GridItemSection>
                                        <GridItemSection>
                                            <Container>
                                                <GridItemHeading>2. Payment Frequency</GridItemHeading>
                                            </Container>
                                            <List horizontal>
                                                {
                                                    this.props.calculatorOptions[this.state.selectedPlateFormatId] !== undefined && this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes.map((quote, index) => {
                                                        return (
                                                            <List.Item key={index}>
                                                                <LaybyCalculatorListContent disabled={this.state.selectedPaymentFrequencyId !== index} onClick={() => this.handlePaymentFrequencyChange(index)}>
                                                                    {quote.installmentFrequency} Days
                                                                </LaybyCalculatorListContent>
                                                            </List.Item>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </GridItemSection>
                                        <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                            <GridItemSection>
                                                <KpButton id="lc-sal" buttonType='primary' link='/create' color={theme.brand.colors.blue}>Start A Layby</KpButton>
                                            </GridItemSection>
                                        </Responsive>
                                    </ContentContainer>
                                </Grid.Column>
                                <Grid.Column floated='right' textAlign='center' mobile={16} tablet={8} computer={6}>
                                    <LaybyCalculatorResultCard backgroundColor={theme.brand.colors.white}>
                                        <Grid divided='vertically'>
                                            <ResultKeyInfoContainer width={16} textAlign='center'>

                                                <strong>Installment's from</strong>
                                                <ResultKeyInfo>
                                                    <LaybyHighlight>${this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].paymentAmount}</LaybyHighlight>&nbsp; every &nbsp;<LaybyHighlight>{this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].installmentFrequency}</LaybyHighlight>&nbsp; days
                                                    </ResultKeyInfo>
                                                <Paragraph2 color={theme.brand.colors.darkGrey}>For {this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].installmentCount} installment's</Paragraph2>

                                            </ResultKeyInfoContainer>
                                            <Grid.Row>
                                                <Grid.Column width={12} textAlign='left' floated='left'>
                                                    <Paragraph2 color={theme.brand.colors.darkGrey}>
                                                        ${this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].paymentAmount} x {this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].installmentCount} installment's
                                                    </Paragraph2>
                                                </Grid.Column>
                                                <Grid.Column width={4} textAlign='right' floated='right'>
                                                    <strong>
                                                        <Paragraph2 color={theme.brand.colors.darkGrey}>
                                                            ${this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].totalAmount}
                                                        </Paragraph2>
                                                    </strong>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={8} textAlign='left' floated='left'>
                                                    <Paragraph2 color={theme.brand.colors.darkGrey}>
                                                        Interest
                                                    </Paragraph2>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign='right' floated='right'>
                                                    <strong>
                                                        <Paragraph2 color={theme.brand.colors.darkGrey}>
                                                            $0
                                                        </Paragraph2>
                                                    </strong>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={8} textAlign='left' floated='left'>
                                                    <strong>
                                                        <Paragraph2 color={theme.brand.colors.black}>
                                                            Total
                                                        </Paragraph2>
                                                    </strong>
                                                </Grid.Column>
                                                <LaybyCalculatorTotalPriceContainer width={8} textAlign='right' floated='right'>
                                                    <Legal color={theme.brand.colors.disabled}>From</Legal>
                                                    <strong>
                                                        <Paragraph2 color={theme.brand.colors.black}>
                                                            ${this.props.calculatorOptions[this.state.selectedPlateFormatId].LaybyQuotes[this.state.selectedPaymentFrequencyId].totalAmount}*
                                                        </Paragraph2>
                                                    </strong>
                                                </LaybyCalculatorTotalPriceContainer>
                                            </Grid.Row>
                                        </Grid>
                                    </LaybyCalculatorResultCard>
                                    <Legal color={theme.brand.colors.disabled}>*Plate designs increase the price of your layby</Legal>
                                    <br></br>
                                    <br></br>
                                    <Legal color={theme.brand.colors.disabled}>Credit or debit card payments will incur an extra 1.9% processing fee at checkout.</Legal>
                                    <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                        <MobileLaybyCalculatorButtonContainer>
                                            <KpButton id="lc-sal" buttonType='primary' link='/create' color={theme.brand.colors.blue}>Start A Layby</KpButton>
                                        </MobileLaybyCalculatorButtonContainer>
                                    </Responsive>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>

                }
                {
                    isLoading &&
                    <Grid centered>
                        <Grid.Column width={16} textAlign='center'>
                            <Icon name='circle notch' size='massive' loading />
                        </Grid.Column>
                    </Grid>
                }
            </div>
        )
    }

}

export default LaybyCalculator