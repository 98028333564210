import React  from 'react'
import styled from '@emotion/styled'
import {

} from '@elements/index';
import mq from '@styles/mq'
import {
    Segment,
    ListContent,
    GridColumn
} from 'semantic-ui-react' 
import theme from '@styles/theme';
import { H4 } from '@styles/Global.styles';

export const ComponentSegment = styled(Segment)`
    &&&&{
        margin: 0;
        padding: 100px 0 !important;
        border: none !important;
        background-color: ${theme.brand.colors.beige} !important;
    }
`

export const LaybyCalculatorListContent = styled(ListContent)`
    padding: 7.5px 15px;
    color: white;
    background-color: ${props => props.disabled ? theme.brand.colors.disabled : theme.brand.colors.green};
    cursor: ${props => props.disabled ? 'pointer' : 'standard'};
    border-radius: 5px;
    font-size: 14px;
    padding-top: 10px;
`

export const PlateImage = styled.img`
    width: 150px;
`

export const GridItemSection = styled.div`
    margin-bottom: 30px;
`

export const GridItemHeading = styled(H4)`
    line-height: 31px !important;
`

export const ContentContainer = styled.div`
    max-width: 500px;
`

export const PlateTypeDescriptionSection = styled.div`
    margin-top: 15px;
`

export const LaybyCalculatorResultCard = styled(({backgroundColor, ...rest}) =><div {...rest} />)`  
    background-color: ${props => props.backgroundColor};
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 20px;
    padding: 30px;

    ${mq({
        padding:['30px','20px','20px', '30px']
    })}

    > .grid > .row {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
`

export const ResultKeyInfoContainer = styled(GridColumn)`
    :before {
        top: unset !important;
        bottom: 0;
        box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15) !important;
    }
    margin-bottom: 10px;
    padding-left: 0 !important;
    padding-right: 0 !important;
`

export const ResultKeyInfo = styled.div`
    margin: 10px 0;
`

export const LaybyHighlight = styled.span`
    font-size: 32px;
    font-weight: bold;
    color: ${theme.brand.colors.green};
`
export const LaybyCalculatorTotalPriceContainer = styled(GridColumn)`
    > small {
        font-size: 10px;
        padding-right: 2px;
        vertical-align: top;
    }

    > strong > p {
        display: inline-block;
    }
`

export const MobileLaybyCalculatorButtonContainer = styled.div`
    padding-top: 15px;
`